.rowContainer {
    flex: 1;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 60px;
    align-items: center;
    border-bottom: 0.5px solid var(--color-pink);
    gap: 1rem;
}
.row {
    flex: 1;
    display: flex;
    align-items: center;
}
.row:first-child {
    flex: 0.5 !important;
    display: flex;
    align-items: center;
}
.row:last-child {
    justify-content: space-between;
}
.rowContainer a {
    color: var(--color-pink);
    text-decoration: none;
}
.rowId {
    width: 20px;
    background-color: var(--color-pink);
    height: 2px;
    border-radius: 2rem;
    margin-right: 1rem;
}
.rowName {
    color: var(--color-pink);
    font-weight: 600;
}
.rowUrl {
    color: var(--color-pink);
}
.roundIcon {
    background-color: var(--color-pink);
    border-radius: 50%;
    padding: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .rowContainer {
        flex-direction: row;
        align-items: space-between;
    }
    .rowDomain {
        display: none;
    }
    .rowStatusText {
        display: none;
    }
    .row:last-child {
        justify-content: flex-end !important;
    }
}

@media (max-width: 468px) { 
    .rowContainer {
        flex-direction: row;
    }
    .rowDomain {
        display: none;
    }
    .rowStatusText {
        display: none;
    }
    .row:last-child {
        justify-content: flex-end !important;
    }
}
