:root {
    --color-yellow: #F1CC6F;
    --color-green: rgba(38, 216, 155, 1);
    --color-green-shade: rgba(38, 216, 155, 0.1);
    --color-purple: #0B1729;
    --color-purple-dark: #091220;
    --color-pink: #d82655;
    --color-pink-shade: rgba(216,38,85, 0.1);
    --color-pink-transparent: rgba(216,38,85,0.4);
}
html {
    background-color: var(--color-purple);
}
body {
    background-color: var(--color-purple);
}
#root {
    background-color: var(--color-purple);
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.bold {
    font-weight: bold;
}
.sectionSubtitle {
    color: #fff;
    font-weight: 600;
    font-size: 1.6rem;
    display: flex;
    width: 50vw;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0;
}
.sectionText {
    color: #fff;
    font-size: 1.2rem;
    display: flex;
    width: 80vw;
    justify-content: flex-start;
    flex-direction: column;
}
.sectionContainerLower {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 4rem;
}
.statusContainer {
    background-color: var(--color-purple-dark);
    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;
    min-height: 60vh;
    border: 1px solid var(--color-pink);
}
